.avatar-with-name {
  display: flex;
  align-items: center;
  gap: 15px;
  min-width: 150px;

  img {
    height: 40px;
    width: 40px;
    border-radius: 20px;
  }
}
