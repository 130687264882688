@import "../../../designSystem/colors";

.personalInformation {
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 34% 66%;
  border-bottom: solid 1px $secondary-50-color;
  &__details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "personalInformationFirstName personalInformationLastName"
      "personalInformationPhoneNumber personalInformationMobile"
      "personalInformationEmail ."
      "personalInformationDivider personalInformationDivider"
      "personalInformationRole .";
    row-gap: 2rem;
    column-gap: 2rem;
  }
  &__firstName {
    grid-area: personalInformationFirstName;
  }
  &__lastName {
    grid-area: personalInformationLastName;
  }
  &__phoneNumber {
    grid-area: personalInformationPhoneNumber;
  }
  &__mobile {
    grid-area: personalInformationMobile;
  }
  &__email {
    grid-area: personalInformationEmail;
  }
  &__role {
    grid-area: personalInformationRole;
  }
  &__divider {
    grid-area: personalInformationDivider;
  }
}
