@import "../../../designSystem/colors.scss";

.office365Activation {
  & a {
    color: $secondary-80-color;
    &:hover{
      color: $accent-new-120-color;
    }
  }
  &__footer {
    margin-top: 2rem;
    & > button {
      margin-top: 1.5rem;
    }
  }
  &__status {
    height: 1rem;
    display: flex;
    flex-direction: row;

    & > img {
      width: 1rem;
      margin-right: 1rem;
      animation: 1s linear infinite inProgress;
      rotate: 360deg;
      transition: rotate 2s;
    }
  }

}