@import "../../../designSystem/colors";

.findingSummary {
  grid-area: finding__summary;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  & > div:nth-child(-n + 3) {
    border-bottom: 1px solid $secondary-30-color;
  }
  &__header {
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
  &__affectedSeverity,
  &__firstSeenLastSeen {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  &__severity {
    & > p {
      margin-top: 0.5rem;
    }
    &__bar {
      margin-top: 1rem;
      height: 0.5rem;
      width: 6rem;
      &--critical {
        background-color: $status-critical-100-color;
      }
      &--low {
        background-color: $status-low-100-color;
      }
      &--medium {
        background-color: $status-medium-100-color;
      }
      &--high {
        background-color: $status-high-100-color;
      }
      &--informational {
        background-color: $status-neutral-100-color;
      }
    }
  }
  &__firstSeen,
  &__lastSeen {
    display: flex;
    flex-direction: column;
    & > p {
      margin-top: 0.5rem;
    }
  }
  &__statusHistory {
    padding-top: 1rem;
    $selector: &;
    &__list {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
    }
    &__item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 3rem;
      &:hover {
        cursor: pointer;
        #{ $selector }__bullet {
          background-color: $secondary-50-color;
        }
        #{ $selector }__date {
          color: $secondary-80-color;
        }
        & > .chip {
          cursor: pointer;
          & > label {
            cursor: pointer;
          }
        }
        & > .chip--new {
          background-color: $status-high-30-dark-color;
        }
        & > .chip--modified {
          background-color: $status-low-100-dark-color;
        }
        & > .chip--remediated {
          background-color: $status-success-80-dark-color;
        }
        & > .chip--unchanged {
          background-color: $status-medium-20-dark-color;
        }
      }
    }
    &__timeline {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 1rem;
      height: 100%;
    }
    &__bullet {
      height: 0.5rem;
      width: 0.5rem;
      background-color: $secondary-30-color;
      border-radius: 50%;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
      &--active {
        background-color: $secondary-100-color;
      }
    }
    &__divider {
      width: 1px;
      background-color: $secondary-30-color;
      height: calc((100% - 0.25rem - 0.5rem - 0.25rem) / 2);
      &--hidden {
        background-color: $secondary-05-color;
      }
    }
    &__date {
      margin-left: auto;
      color: $secondary-30-color;
      &--active {
        color: $secondary-100-color;
      }
    }
  }
}
