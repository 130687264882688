@import "../colors";

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1.8rem 1.5rem;
  border-bottom: solid 1px $secondary-50-color;
  width: 100%;
  &__icon {
    display: flex;
    margin-right: 0.5rem;
    img {
      height: 22px;
      filter: brightness(0) saturate(100%) invert(9%) sepia(37%) saturate(3457%)
        hue-rotate(181deg) brightness(104%) contrast(101%);
    }
  }
  &__text {
    display: flex;
    width: 99%; /* somehow elements are being pushed out of bundary creating an horizontal scroll. with 99 it recalculates*/
    flex-direction: column;
    justify-content: flex-start;
  }
  &___subTitle {
    color: $secondary-60-color;
  }
}
