@import "../colors";

.tableHeader {
  padding: 0.5rem;
  border-bottom: solid 1px $secondary-30-color;
  &:hover {
    cursor: pointer;
  }
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  &__sortIcon {
    margin-left: 1rem;
    transition: transform 0.2s ease-out 0s;
    &::after {
      content: '↑';
    }
    &--down {
      transform: rotate(180deg);
    }
  }
}