@import "../colors.scss";

.modalContainer {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalContainer_content {
  max-width: 444px;
  width: 41%;
  border: none;
  background-color: $secondary-05-color;
}

.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5em;
  border-bottom: solid 1px $secondary-100-color;
}
.modalClose {
  border: none;
  background-color: $secondary-05-color;
  font-size: 1.2em;
}

.modalClose:hover {
  cursor: pointer;
}

.modalInfo {
  display: flex;
  flex-direction: column;
  margin: 1.5em;
}
.modalFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 3em;
}

.modalFooterCancel,
.modalFooterSave {
  border: none;
  font-size: 1em;
  color: $secondary-100-color;
  height: 2.5em;
  padding: 0.75em;
}

.modalFooterSave {
  background-color: $accent-100-color;
}

.modalFooterCancel {
  margin-right: 1em;
}

.modalFooterCancel:hover,
.modalFooterSave:hover {
  cursor: pointer;
}

.modalFooterSave:hover {
  background-color: $accent-120-color;
}

.modalFooterSave:disabled {
  background-color: $secondary-20-color;
  color: $secondary-40-color;
  cursor: not-allowed;
}
