@import "../colors";

.chip {
  width: -moz-fit-content;
  width: fit-content;
  &--disabled {
    opacity: 0.5;
  }
  &--oval {
    padding: 0.5rem 1rem;
    border-radius: 2rem;
  }
  &--rectangle {
    height: 0.5rem;
    width: 1rem;
  }
  /*Inspect severity values*/
  &--high {
    background-color: $status-high-100-color;
  }
  &--medium {
    background-color: $status-medium-100-color;
  }
  &--low {
    background-color: $status-low-100-color;
  }
  &--critical {
    background-color: $status-critical-100-color;
  }
  &--informational {
    background-color: $status-neutral-100-color;
  }
  /*Inspect status values*/
  &--new {
    background-color: $status-high-30-color;
  }
  &--modified {
    background-color: $status-low-100-color;
  }
  &--remediated {
    background-color: $status-success-80-color;
  }
  &--unchanged {
    background-color: $status-medium-20-color;
  }
}
