/*Colors from Design System palette https://www.figma.com/file/7mieTU49bblcdOshoCuUMW/2020_012_SOTERIA_Product_editor?node-id=267%3A0*/
$primary-dark-120-color: #001d2f;
$accent-100-color: #76c9d7;
$accent-120-color: #2e9fb2;
$primary-150-color: #0c889d;
$primary-accent-120-color: #2e9fb2;
$secondary-100-color: #00263e;
$secondary-120-color: #001726;
$secondary-100-color-disabled: rgba(0, 38, 62, 0.3);
$secondary-80-color: #2c4757;
$secondary-60-color: #5d8297;
$secondary-50-color: #83a6b5;
$secondary-40-color: #a7c4cf;
$secondary-30-color: #cedde3;
$secondary-20-color: #dde6ea;
$secondary-10-color: #edf2f4;
$secondary-05-color: #f7f9fa;
$secondary-05-color-transparent: rgba(247, 249, 250, 0);
$secondary-00-color: white;
$status-active-100-color: #41a0fe;
$status-active-30-color: #b8dcff;
$status-neutral-100-color: #a7c4cf;
$status-critical-100-color: #ff3333;
$status-high-30-color: #ffb6b6;
$status-high-30-dark-color: #e5a3a3;
$status-high-100-color: #ff7474;
$status-medium-20-color: #ffd3b4;
$status-medium-20-dark-color: #e5bda2;
$status-medium-100-color: #ffae74;
$status-low-100-color: #ffe380;
$status-low-100-dark-color: #e5cc73;
$status-warning-snackbar: #ffc107;
$status-success-80-color: #9aecbf;
$status-success-80-dark-color: #8ad4ab;
$status-success-100-color: #80e5a9;
$menu-divider-color: rgba(255, 255, 255, 0.2);
$login-button-disabled: #c4c4c4;
$accent-new-10-color: #ebf4fc;
$accent-new-90-color: #559bed;
$accent-new-100-color: #4290eb;
$accent-new-110-color: #2780e8;
$accent-new-120-color: #3278bf;
