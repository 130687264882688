@import "../colors.scss";
@import "../variables.scss";

.menu-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu-container__list > .menu-container,
.menu-container > .menu-container__toggler {
  width: 100%;
}

.menu-container > .menu-container__toggler {
  cursor: pointer;
}

.menu-container__list > .menu-container > .menu-container__list {
  top: 0;
  left: 100%;
}

.menu-container__list.menu-container__list--left
  > .menu-container
  > .menu-container__list {
  right: 100%;
  left: unset;
}

.menu-container__list {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  color: $secondary-10-color;
  background-color: $secondary-100-color;
  list-style-type: none;
  max-height: 361px;
}

.menu-container__list.menu-container__list--scroll {
  max-height: 360px;
  overflow-y: auto;
}

.menu-container__list.menu-container__list--top {
  bottom: calc(100% + 2px);
  top: unset;
}

.menu-container__list.menu-container__list--left {
  left: unset;
  right: 0;
}

.menu-container__list .menu-container__item {
  padding: 0.7em;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &--active::after {
    content: " ";
    background: url($success-icon);
    background-size: 10px;
    background-size: contain;
    width: 18px;
    height: 18px;
    display: inline-block;
    background-repeat: no-repeat;
  }
}

.menu-container__list.menu-container__list--left .menu-container__item {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.menu-container__list .menu-container__item:hover {
  background-color: $primary-dark-120-color;
}

.menu-container__list .menu-container__item__content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu-container__item__content__text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 1em;

  &-value {
    overflow-wrap: anywhere;
  }
}

.menu-container__item__content__text {
  &-subvalue {
    color: $secondary-50-color;
  }
}

.menu-container__item .menu-container__item__submenu {
  position: absolute;
  right: 100%;
}

.menu-container__list
  .menu-container__item
  .menu-container__item__icon.menu-container__item__icon--default-color,
.menu-container__list .menu-container__item .menu-container__item__chevron {
  filter: brightness(0) saturate(100%) invert(99%) sepia(21%) saturate(365%)
    hue-rotate(169deg) brightness(98%) contrast(95%);
  height: 1.3em;
}

.menu-container__list .menu-container__item .menu-container__item__chevron {
  margin-left: 1em;
}

.menu-container__list.menu-container__list--left
  .menu-container__item
  .menu-container__item__chevron {
  transform: rotate(180deg);
  margin-left: 0;
  margin-right: 1em;
}

.click-outside {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
}

.click-outside + .menu-container__list,
.click-outside ~ .menu-container__toggler {
  z-index: 1000;
}

.menu-container__list__divider {
  height: 1px;
  width: calc(100% - 1.4em);
  margin: 4px 0.7em;
  background-color: $menu-divider-color;
}
