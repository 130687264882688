@import "../../designSystem/colors.scss";

.tableContainer {
  width: 100%;
  position: relative;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.no-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  margin: 30px auto;

  &__image {
    width: 50px;
    margin-bottom: 10px;
  }

  &__title,
  &__description {
    text-align: center;
    font-size: 16px;
    line-height: 19px;
  }

  &__title {
    color: $secondary-120-color;
    margin-bottom: 10px;
    font-weight: 500;
  }

  &__description {
    text-align: center;
    color: $secondary-60-color;
    font-weight: 400;
  }
}
