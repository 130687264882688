@import "../colors.scss";

.avatarContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  font-size: 1em;
  color: $secondary-10-color;

  &--disabled:hover {
    cursor: default;
  }

  &--enabled:hover {
    cursor: pointer;
  }

  &--default {
    background-color: $secondary-100-color;

    &-secondary {
      background-color: $secondary-80-color;
    }
  }

  &--active {
    background-color: $accent-new-100-color;
  }

  .iconContainer,
  .avatar__image {
    width: 100%;
    border-radius: 20px;
  }
}
