.onboardingContent{
  display: flex;
  flex-direction: column;
  height: 100%;
  &--center{
    justify-content: center;
  }
  &__details{
    &--top {
      margin-top: 2rem;
    }
    &--center {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }
    &--heightMax{
      height: 100%;
    }
  }
  &__footer{
    margin-top: auto;
  }

}