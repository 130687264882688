.navBarContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding:1rem 1rem 1rem;
  width: 100%;
  &__actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: auto;
  }
}
