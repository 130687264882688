@import "../designSystem/colors";

.finding {
  flex: 1;
  display: flex;
  flex-direction: column;
  &__loading {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > img {
      width: 4rem;
      animation: 1s linear infinite inProgress;
      rotate: 360deg;
      transition: rotate 2s;
    }
  }
  &__content {
    flex: 1;
    display: grid;
    grid-template-columns: minmax(266px, 34%) minmax(551px, calc(66% - 1px));
    grid-template-areas: "finding__summary finding__details";
  }
}
