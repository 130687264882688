@import "../../designSystem/colors";

.subscriptionPending {
  & a {
    text-decoration: underline;
    color: $secondary-60-color;

    &:hover {
      color: $accent-new-110-color;
    }
  }
  & button {
    margin-top: 1.5rem;
  }
}