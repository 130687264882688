@import "../designSystem/colors";

.onboardingStage {
  &__icon {
    width: 4rem;
    &--inProgress {
      animation: 1s linear infinite inProgress;
      rotate: 360deg;
      transition: rotate 2s;
    }
  }
  &__step, &__title {
    &--success, &--inProgress {
      color: $accent-new-100-color;
    }
    &--warning {
      color: $secondary-100-color;
    }
  }
  &__info {
    height: 100%;
    margin-top: 1.5rem;
  }
}

@keyframes inProgress {
  from {
    rotate: 0deg;
  }
  to {
    rotate: 360deg;
  }

}