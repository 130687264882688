@import "../designSystem/colors";

.onboardingWorkflow {
  margin-top: 1rem;
  & a {
    color: $secondary-60-color;
    &:hover {
      color: $accent-new-110-color;
    }
  }
  &>.button {
    margin-top: 2rem;
  }
}