@import "../designSystem/colors";

.onboarding {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 25% 1px calc(75% - 1px);
  &__sidePanel{
    padding: 1rem;
  }
  &__separator{
    background-color: $secondary-50-color;
  }
  &__content{
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
}
