@import "designSystem/colors";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  background-color: $secondary-05-color;
  color: $secondary-100-color;
  font-family: "basier_squareregular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: "basier_squareregular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: normal;
}

h2 {
  font-family: "basier_squareregular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 40px;
}

h3 {
  font-family: "basier_squaremedium", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
}

h4 {
  font-family: "basier_squareregular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
}

h5 {
  font-family: "basier_squaremedium", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
}

h6 {
  font-family: "basier_squaremedium", -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
}

button {
  font-family: "basier_squareregular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
}

label {
  font-family: "basier_squareregular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
}

.label {
  @extend label;
  &--bold {
    font-weight: 500;
  }
  &--bolder {
    font-weight: bolder;
  }
}

input {
  font-family: "basier_squareregular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
}

textArea {
  font-family: "basier_squareregular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
}

.noDecoration {
  text-decoration: none;
  color: $secondary-100-color;
  &:hover {
    color: $accent_new-110-color;
  }
}

.has-tooltip:hover {
  position: relative;
  .tooltipContainer {
    display: flex;
  }
}

.no-decoration {
  text-decoration: none;
  color: $secondary-100-color;
}

.capitalize {
  text-transform: capitalize;
}
