@import "../colors.scss";

.snackbarListContainer {
  z-index: 3;
  position: fixed;
  bottom: 1.5em;
  left: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
}

.snackbarContainer {
  background-color: $primary-dark-120-color;
  color: $secondary-10-color;
  width: fit-content;
  max-height: 500px;
  transition: opacity 1s ease, max-height 1s ease;
}

.snackbarContainer.fade {
  opacity: 0;
  max-height: 0;
}

.snackbarContainer.error {
  background-color: $status-high-100-color;
  color: $secondary-00-color;
}

.snackbarContainer.warning {
  background-color: $status-warning-snackbar;
  color: $secondary-00-color;
}

.snackbarContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1em;
}

.contentContainer {
  position: relative;
}

.snackbarContent > .snackbarContent__icon {
  width: 1.2em;
  height: 1.2em;
  filter: brightness(0) saturate(100%) invert(93%) sepia(12%) saturate(59%)
    hue-rotate(150deg) brightness(103%) contrast(92%);
}

.snackbarContainer.error > .snackbarContent > .snackbarContent__icon {
  filter: brightness(0) saturate(100%) invert(97%) sepia(3%) saturate(12%)
    hue-rotate(119deg) brightness(105%) contrast(104%);
}

.snackbarContent__text {
  margin-left: 1em;
}

.loadingSnackbar {
  position: relative;
  width: 25px;
}

.loadingSnackbar img {
  width: 40px;
  height: 40px;
}
