@import "../../designSystem/colors";

.subscriptionDetails{
  &__tenant {
    margin-top: 5rem;
    padding-bottom: 4rem;
    border-bottom: 1px solid $secondary-50-color;
    & h5{
      color: $accent-new-100-color;
    }
    &__details {
      margin-top: 1rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  &__user {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    &__title{
      color: $accent-new-100-color;
    }
    &__information{
      margin-top: 1rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 3rem 3rem 1fr;
      grid-template-areas:  "firstName lastName"
                            "phoneNumber email"
                            ". .";
      grid-row-gap: 1rem;
    }
    &__firstName{
      grid-area: firstName;
    }
    &__lastName{
      grid-area: lastName;
    }
    &__phoneNumber{
      grid-area: phoneNumber;
    }
    &__email{
      grid-area: email;
    }
  }
}

.subscriptionFooter{
  &__status {
    display: flex;
    flex-direction: row;
    &__image {
      width: 1rem;
      margin-right: 1rem;
      &--inProgress{
        animation: 1s linear infinite inProgress;
        rotate: 360deg;
        transition: rotate 2s;
      }
    }
  }
  &__cta {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
  }
}
