@import "../designSystem/colors.scss";

.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 72px);

  .not-found-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    left: calc(50% - 200px);
    gap: 10px;

    &__image {
      width: 50px;
    }

    &__title,
    &__description {
      text-align: center;
      font-size: 16px;
      line-height: 19px;
    }

    &__title {
      color: $secondary-120-color;
      font-weight: 500;
    }

    &__description {
      text-align: center;
      color: $secondary-60-color;
      font-weight: 400;
      margin-bottom: 30px;
    }
  }
}
