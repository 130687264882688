@import "../../../designSystem/colors.scss";

.inspectors {
  padding: 1.5rem;
  h5 {
    margin-bottom: 60px;
  }
  .date-cell {
    min-width: 100px;
  }
  .notes-cell {
    max-width: 700px;
  }

  .tableCell {
    &-date,
    &-date-not-available {
      font-size: 14px;
    }
    &-date-not-available {
      display: block;
    }
  }
  .wrapped {
    &.tooltipContainer {
      max-width: 107px;
      white-space: unset;
    }
  }
}

.tableRow {
  & > .tableCell .pause-inspectors-eye-icon--paused {
    visibility: hidden;
  }

  &:hover > .tableCell .pause-inspectors-eye-icon--paused {
    visibility: visible;
  }
}
