@import "../designSystem/colors";

.scan {
  &__totals,
  &__status {
    .label--bold {
      font-weight: 700;
    }
  }
  &__paused-inspectors,
  &__count,
  &__inspectors {
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: auto;
    }
    h2 {
      margin-top: auto;
    }
    a {
      text-decoration: none;
      color: inherit;
    }
  }
  &__paused-inspectors-clickable {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    .label {
      cursor: pointer;
    }
  }
  &__paused-inspectors {
    // cursor: pointer;
    &-link {
      color: $accent-new-90-color;
      font-weight: bold;
    }
  }
  &__text {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    & > p {
      color: $secondary-60-color;
    }
  }
  &__image {
    &--inProgress {
      animation: 1s linear infinite inProgress;
      rotate: 360deg;
      transition: rotate 2s;
    }
    &--empty {
      width: 8rem;
    }
    &--warning {
      width: 4rem;
    }
  }
  &__info {
    flex: 1;
    display: grid;
    grid-template-columns: minmax(154px, 34%) minmax(305px, calc(66% - 1px));
  }
  &__summary {
    display: flex;
    flex-direction: column;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    &-tooltip {
      min-width: 107px;
      white-space: unset;
    }
    &__header {
      padding-top: 1rem;
      padding-bottom: 2.7rem;
      border-bottom: 1px solid $secondary-30-color;
    }
  }
  &__totals {
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(154px, 1fr));
    border-bottom: 1px solid $secondary-30-color;
  }
  &__severity,
  &__status {
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $secondary-30-color;

    .label--bold {
      font-weight: bold;
    }
    &__totals {
      margin-top: 1rem;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(46px, 1fr));
      gap: 1rem;
    }
    &__bar {
      margin-top: 1rem;
      height: 0.5rem;
      width: 100%;
      &--critical {
        background-color: $status-critical-100-color;
      }
      &--low {
        background-color: $status-low-100-color;
      }
      &--medium {
        background-color: $status-medium-100-color;
      }
      &--high {
        background-color: $status-high-100-color;
      }
      &--informational {
        background-color: $status-neutral-100-color;
      }
    }
  }
  &__status,
  &__request {
    padding-top: 1rem;
    &__totals {
      margin-top: 1rem;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      column-gap: 1rem;
      row-gap: 1rem;
    }
  }
  &__actions {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 1rem;
    height: 3.6rem;
    & > .actionBarContainer {
      & > .button {
        margin-left: 1rem;
      }
    }
  }
  &__filters {
    width: 13rem;
  }
  &__findings {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-left: 1px solid $secondary-30-color;
    display: flex;
    flex-direction: column;
  }
  &__table {
    margin-top: 1.9rem;
    &__severity {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
    }
  }
  &__request {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    label {
      font-weight: bold;
    }
    button {
      margin-left: 20px;
    }
  }
}
