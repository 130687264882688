@import "../colors";

.breadcrumb {
  display: flex;
  text-wrap: nowrap;
  align-items: center;
  width: 100%;
  .menu-container {
    flex-basis: 0;
    .navLink {
      &__menu {
        display: flex;
        align-items: center;
        width: 20px;
        height: 20px;
        justify-content: center;
        margin-left: 0.2em;

        &:hover {
          background-color: $secondary-100-color;

          img {
            filter: invert(99%) sepia(1%) saturate(0%) hue-rotate(1deg)
              brightness(114%) contrast(100%);
          }
        }
      }
    }
  }

  &__item {
    display: flex;
    font-size: 24px;
    &-last {
      overflow: hidden;
      .navLink {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .navLink {
      text-decoration: none;
      color: $secondary-100-color;
      &--active {
        font-family: "basier_squaremedium", -apple-system, BlinkMacSystemFont,
          "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
          "Droid Sans", "Helvetica Neue", sans-serif;
      }
      &--inactive {
        font-family: "basier_squareregular", -apple-system, BlinkMacSystemFont,
          "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
          "Droid Sans", "Helvetica Neue", sans-serif;
      }
      &--loading {
        min-width: 100px;
      }
      &__separator,
      &__separator {
        margin-left: 0.5em;
        margin-right: 0.5em;
      }
    }
  }
}
