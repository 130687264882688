@import "../../../designSystem/colors.scss";

.office365AgreementDetails{
  height: calc(100% -  2rem);
  & p {
    margin-bottom: 1rem;
  }
  & ul, & ol {
    margin-left: 1rem;
    text-align: left;
  }
  & a {
    color: $secondary-80-color;
    &:hover{
      color: $accent-new-120-color;
    }
  }
}