@import "../designSystem/colors";

.onboardingFooter {
  color: $secondary-60-color;
  & a {
    color: inherit;
    &:hover {
      color: $accent-new-110-color;
    }
  }
}
