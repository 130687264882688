@import "../colors";

.button {
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
  &--default {
    height: 2.5em;
    padding: 0.7em;
    border: none;
    color: $secondary-100-color;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-wrap: nowrap;
    gap: 10px;
    min-width: fit-content;
  }
  &--primary {
    background-color: $accent-new-100-color;
    color: $accent-new-10-color;
    &:not(.loading, :disabled):hover {
      background-color: $accent-new-120-color;
    }
    &:disabled {
      background-color: $secondary-40-color;
    }
  }
  &--secondary {
    background-color: transparent;
    &:not(.loading, :disabled):hover {
      background-color: $secondary-10-color;
    }
  }
  &--link {
    background: none;
    border: none;
    text-decoration: underline;
    color: $secondary-80-color;
    &:hover {
      color: $accent-new-120-color;
    }
  }
}

.button--primary img,
.button--secondary img,
.button--primary svg,
.button--secondary svg {
  height: 1.25em;
}

.button--primary:not(.button--iconColor) img,
.button--primary:not(.button--iconColor) svg {
  filter: brightness(0) saturate(100%) invert(100%) sepia(49%) saturate(2246%)
    hue-rotate(178deg) brightness(106%) contrast(98%);
}

.button--secondary:not(.button--iconColor) img,
.button--secondary:not(.button--iconColor) svg {
  filter: brightness(0) saturate(100%) invert(12%) sepia(32%) saturate(2633%)
    hue-rotate(174deg) brightness(92%) contrast(103%);
}

.button--primary:not(.loading):disabled {
  background-color: $secondary-20-color;
  color: $secondary-50-color;
}

.button--primary.active {
  background-color: $secondary-100-color;
}

.button--transparent {
  background-color: transparent;
}

.button--secondary:not(.loading):disabled {
  color: $secondary-40-color;
}

.button:disabled:not(.loading) img,
.button:not(.loading):disabled svg {
  filter: brightness(0) saturate(100%) invert(91%) sepia(4%) saturate(2243%)
    hue-rotate(164deg) brightness(94%) contrast(71%);
}

.button--secondary.active {
  color: $accent-new-120-color;
}

.button--secondary.active img,
.button--secondary.active svg {
  filter: brightness(0) saturate(100%) invert(36%) sepia(91%) saturate(595%)
    hue-rotate(174deg) brightness(95%) contrast(82%);
}

.button--transparent {
  cursor: none;
}
