@import "../colors";

.tabs {
  &-nav {
    display: flex;
    list-style-type: none;
    margin-bottom: 2em;
    font-size: 14px;

    li {
      padding: 10px 16px;
      color: $secondary-100-color;
      font-weight: 600;
      cursor: pointer;
    }

    li.active {
      color: $accent-new-100-color;
      background-color: $secondary-10-color;
    }
  }
}
