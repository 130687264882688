.avatarMenuContainer {
    margin-left: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.avatarMenuContainer__anchor{
    position: relative;
    width: 100%;
    margin-top: 0.125em;
}

.avatarMenuContainer__menuItem__text{
    margin-left: 1em;
}