.office365Permissions {
  height: 100%;
  & p {
    margin-bottom: 1rem;
  }
  & ul, & ol {
    margin-left: 1rem;
    text-align: left;
  }
}
