.loginErrorDetails{
  & img{
    width: 4rem;
  }
  & h3{
    margin-top: 2rem;
  }
  & p{
    margin-top: 1.5rem;
  }
  & button{
    margin-top: 4rem;
  }
}