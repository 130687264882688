@import "../../../designSystem/colors.scss";
@import "../../../designSystem/colors.scss";

.inspector-managment {
  &-resume {
    .inspector-managment__information {
      &-text {
        margin-bottom: 20px;
      }
      &-subtitle {
        color: $secondary-100-color;
      }
    }
  }
  &__information {
    margin-bottom: 25px;
    &-subtitle {
      color: $secondary-60-color;
      font-size: 14px;
    }
  }
  &__period {
    margin-bottom: 25px;
    .label {
      display: inline-block;
      margin-bottom: 5px;
    }
  }
  &__notes {
    &-label {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      margin-bottom: 5px;
      span {
        margin-left: auto;
        color: $secondary-40-color;
        font-size: 12px;
      }
    }
  }
  .label--bold {
    font-weight: 600;
  }
}
