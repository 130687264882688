@import "../colors.scss";

.tooltipContainer {
  position: absolute;
  z-index: 1;
  display: none;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5em;
  font-size: 12px;
  top: calc(100% + 5px);
  left: 0;
  width: fit-content;
  white-space: nowrap;

  &--top {
    top: unset;
    bottom: calc(100% + 5px);
  }

  &--right {
    left: unset;
    right: 0;
  }

  &--info {
    background-color: $primary-dark-120-color;
    color: $secondary-10-color;
  }

  &--error {
    background-color: $status-high-100-color;
    color: $secondary-00-color;
  }

  &__text-container {
    display: flex;
    flex-direction: column;
  }

  &__subtext {
    color: $secondary-50-color;
  }

  & > img {
    width: 1.2em;
    margin-right: 1em;
  }
}
