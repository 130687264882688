@import "../colors";

.textAreaContainer{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  &--heightMax{
    height: 100%;
  }
  &--widthDefault{
    width: 18em;
  }
  &--widthMax{
    width: 100%;
  }
  &__text{
    resize: none;
    border: none;
    padding: 0.5em;
    background-color: $secondary-10-color;
    &--heightDefault{
      height: 8rem;
    }
    &--heightMax{
      height: 100%;
    }
    &--widthDefault{
      width: 18em;
    }
    &--widthMax{
      width: 100%;
    }
    & ::placeholder{
      opacity: 0.4;
    }
    & :hover{
      outline: solid 1px $secondary-30-color;
      border: none;
    }
    & :focus{
      outline: none;
      border:none;
      background-color: $secondary-20-color;
    }
    &--error{
      color: $status-high-100-color;
    }
  }
}

