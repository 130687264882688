@import "../../../designSystem/colors.scss";

.tenantInformation {
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 34% 66%;
  &__details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
  }
}
