@import "../designSystem/colors.scss";

.settings {
  &__scan-frequency {
    border-bottom: solid 1px $secondary-50-color;
  }
  &__tenant {
    padding-bottom: 3rem;
    border-bottom: solid 1px $secondary-50-color;
  }

  &__scan-frequency,
  &__inspectors,
  &__scan-request {
    padding: 1.5rem;
    display: grid;
    grid-template-columns: 34% 66%;
    &__holders {
      display: grid;
      row-gap: 0.5rem;
      &_information {
        p {
          font-size: 14px;
        }
      }
    }
  }
  &__scan-request {
    &-button-holder {
      display: grid;
      row-gap: 0.5rem;
      width: 25%;
      button {
        width: 40%;
      }
    }
  }
  &__scan-frequency {
    &_options-holder {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem;
    }
  }
  &__scan-frequency__radio {
    margin: 0 1rem 0 0;
    accent-color: $accent-new-120-color;
    vertical-align: middle;
    h5 {
      border: 1px solid gray;
    }
  }
}
