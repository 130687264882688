@import "../designSystem/colors";

.organizations-list {
  display: flex;
  flex-direction: column;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 1.5rem 1.5rem 0;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    & > .actionBarContainer > .input {
      width: 300px;
    }
  }

  &__table {
    margin-top: 1rem;

    &__severity {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;

      &.opaque {
        opacity: 0.5;
      }
    }
  }
}

.organizationsContextMenuContainer {
  visibility: hidden;

  .tableRow:hover & {
    visibility: visible;
  }

  &__menu {
    padding: 0 10px;
    display: flex;
    justify-content: center;
  }
}
