@import "../../../designSystem/colors.scss";

.addUserForm {
  display: flex;
  flex-direction: column;

  .addUserEmailLabel {
    margin-top: 1.5em;
  }

  #addUserEmailInput {
    border: none;
    font-size: 1em;
    height: 2.5em;
    padding-left: 0.5em;
    padding-right: 0.5em;

    &:hover {
      outline: solid 1px $secondary-30-color;
      border: none;
    }

    &:focus {
      outline: none;
      border: none;
    }
  }

  .addUserRoleLabel {
    margin-top: 1.5em;
  }
}
